import React from 'react';
import './Tag.scss';

export function Tag({content}){
    return (
        <div className="tag-container">
            {content}
        </div>
    )
}
