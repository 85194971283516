import React from 'react';
import { Link } from 'gatsby';
import { Tag } from '../Tag/Tag';
import { useTheme } from '../../contexts/ThemeContext';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Footer } from '../Footer/Footer';
import './BlogPosts.scss';


export function BlogPosts({data}) {
    const posts = data.allMarkdownRemark.edges;
    const { mainTextColor, paragraphTextColor, backgroundColor } = useTheme();

    return (
        <div style={{ backgroundColor: backgroundColor }} className="blog-page">
            <div className="post-list">
                {posts.map(post => (
                    <div key={post.node.id} className="post-list__item">
                        <div className="post-list__thumbnail">              
                            <h2 style={{color: mainTextColor}}>{post.node.frontmatter.title}</h2>
                            <div className="tags-container">
                                {
                                    post.node.frontmatter.tags.map( tag => <Tag content={tag} />)
                                }
                            </div>
                            <p 
                                className="post-date"
                                style={{color: mainTextColor}}
                            >
                                {post.node.frontmatter.date}
                            </p>
                            <div style={{ color: paragraphTextColor }} className="post-list__excerpt">{post.node.excerpt}</div>
                            <Link 
                                className="view-post-button" 
                                style={{color: mainTextColor}}
                                to={post.node.frontmatter.path}>
                                    View 
                                <RiArrowRightSLine className="arrow-icon" size={20} />
                            </Link>
                        </div>          
                    </div>
                ))}
            </div>
        </div>
    )
}
