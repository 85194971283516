// import React from 'react';

// export default function Blog() {
//     return (
//         <div>
//             <NavbarTwo />
//         </div>
//     )
// }

import React from 'react';
import { graphql, Link } from 'gatsby';
import NavbarTwo from '../components/Navbar/NavbarTwo';
import { BlogPosts } from '../components/Blog Posts/BlogPosts';
import { Footer } from '../components/Footer/Footer';
import './blog.scss';

export default function Blog({ data }){
  return (
      <>
        <NavbarTwo />
        <BlogPosts data={data} />
        <Footer />
      </>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            path
            tags
        }
      }
    }
  }
  }
`;
